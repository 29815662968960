import { createMuiTheme } from '@material-ui/core/styles';
import { lightBlue } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#082366',
    },
    secondary: {
      main: lightBlue[500],
    },
  },
});
